module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.custommetalroofing.com.au"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"custom-metal-roofing","short_name":"cmr","start_url":"/","background_color":"#211f1f","theme_color":"#211f1f","display":"minimal-ui","icon":"src/images/assets/favicon.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"5deb83f7897a92f59b9ffe5cd7325a78"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
